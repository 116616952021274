export default class Modal {
  show = (content) => {
    $('#modal #modal-content').html(content)
    $('#modal').addClass('is-active')
  }

  close = () => {
    $('#modal #modal-content').html(null)
    $('#modal').removeClass('is-active')
  }
}
