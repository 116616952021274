// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require.context('../images', true, /\.(png|jpg|jpeg|svg|ico)$/);
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("@rails/actiontext")
require('./vip_chapters')
require("jquery")
require("@nathanvda/cocoon")
require('./nominations')
require("trix")

import { DirectUpload } from "@rails/activestorage"
import ImageResize from "@taoqf/quill-image-resize-module/image-resize.min";
import Quill from 'quill/dist/quill.js';
import htmlEditButton from "quill-html-edit-button";
Quill.register('modules/htmlEditButton', htmlEditButton)
import './jquery_validates';
import '../src/application.scss';
import 'tippy.js/dist/tippy.css';
import "core-js/stable";
import "regenerator-runtime/runtime";
import './validates_chapter';
import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'
import BulmaTagsInput from '@creativebulma/bulma-tagsinput';
import BulmaSlider from 'bulma-slider/src/js'
import tippy from 'tippy.js';
import Plyr from 'plyr';
import { toast } from 'bulma-toast';
import flatpickr from "flatpickr";
import TrixImplement from './trix_implement'
import Payments from "./payments";
import Modal from "./modal";
import Routes from '../routes/index.js.erb';
import "./info_convert";

window.Routes = Routes;
Quill.register('modules/imageResize', ImageResize);

$(document).ready(function () {
  //auto run js code
  // Check for click events on the navbar burger icon
  $(".navbar-burger").click(function () {
    // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
    $(".navbar-burger").toggleClass("is-active");
    $(".navbar-menu").toggleClass("is-active");
  });

  $(document).on('click', '.notification > button.delete', function () {
    $(this).parent().addClass('is-hidden');
    return false;
  });

  $('#link-search-with-author').on('click', function () {
    var link = Routes.admin_books_path();
    var author = $('#book_author').val().trim();
    if (author != "") {
      link = Routes.admin_books_path({'q[author_cont]': author});
    }
    $(this).attr('href', link);
  });

  $('.book_update').on('ajax:success', function() {
    let icon = $(this.querySelector('i'));
    if (icon.hasClass('fa-check')) {
      icon.removeClass('fa-check has-text-success');
      icon.addClass('fa-times has-text-grey-light');
    } else {
      icon.removeClass('fa-times has-text-grey-light');
      icon.addClass('fa-check has-text-success');
    }
    toast({
      message: 'Updated',
      type: 'is-success',
      dismissible: true,
      animate: { in: 'fadeIn', out: 'fadeOut' },
    })
  });

  $('.js-toggle-display-show-hide-ios').on('ajax:complete', function() {
    let icon = $(this.querySelector('i'));
    if (icon.hasClass('fa-eye')) {
      icon.removeClass('fas fa-eye');
      icon.addClass('far fa-eye-slash');
    } else {
      icon.removeClass('far fa-eye-slash');
      icon.addClass('fas fa-eye');
    }
  });

  $('.book_toggle_display').on('ajax:complete', function() {
    window.location.reload()
  });

  BulmaTagsInput.attach();
  Plyr.setup('audio');
  tippy('[data-tippy-content]');
  $('input[type=file].file-input').change(function() {
    if (this.files.length > 0) {
      const fileName = $('.file-name');
      fileName.text(this.files[0].name);
    }
  });
  BulmaSlider.attach()

  window.TrixImplement = new TrixImplement;
  window.Payments = new Payments;
  window.Modal = new Modal;
  $('.datetime-picker').flatpickr({
    enableTime: true,
    dateFormat: "Y-m-d H:i"})

  /* Quill editor option */
  var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': ['center', 'right', 'justify'] }],
    ['clean'],
    ['link', 'image', 'video']
  ];

  /* Int editor */
  var quill = new Quill('#editor-inner-html', {
    modules: {
      toolbar: toolbarOptions,
      imageResize: {
        displaySize: true,
        displayStyles: {
          backgroundColor: 'black',
          border: 'none',
          color: 'white'
        },
        modules: [ 'Resize', 'DisplaySize', 'Toolbar']
      },
      htmlEditButton: {
        debug: true,
        msg: "Edit the content in HTML format",
        okText: "Ok",
        cancelText: "Cancel",
        buttonHTML: "&lt;&gt;",
        buttonTitle: "Show HTML source",
        syntax: false,
        prependSelector: 'div#myelement',
        editorModules: {}
      }
    },
    value: $('.js-inner-html-skip-ads').val(),
    theme: 'snow'
  });

  /* Render data to editor */
  quill.root.innerHTML = $('.js-inner-html-skip-ads').val()

  /* Update data inner html */
  $('.js-form-skip-ads').on('submit',function() {
    $('.js-inner-html-skip-ads').val(quill.root.innerHTML);
  })

  /* Upload image */
  function importImage (textEditor) {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      // Ensure only images are uploaded
      if (/^image\//.test(file.type)) {
        uploadImage(textEditor, file);
      } else {
        alert('Only images allowed');
      }
    };
  };

  /* Upload image with active storage */
  function uploadImage (textEditor, file) {
    var fd = new FormData();
    fd.append('blob', file);
    var upload = new DirectUpload(file, '/rails/active_storage/direct_uploads')
    var currentHost = document.location.origin
    upload.create((error, blob) => {
      if (error) {
        alert(error)
      } else {
        insertImage(
          textEditor,
          `${currentHost}/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`
        );
      }
    });
  };

  /* Replace data image */
  function insertImage (textEditor, fileUrl) {
    const range = textEditor.getSelection();
    textEditor.insertEmbed(range.index, 'image', fileUrl);
  };

  quill.getModule('toolbar').addHandler('image', () => {
    importImage(quill);
  });
});
