$(document).ready(function (){
  $('#table-not-pagy').hide();

  $('#show-all').click(function(){
    if (this.checked){
      $('#table-not-pagy').show();
      $('#table-pagy').hide();
    }else{
      $('#table-not-pagy').hide();
      $('#table-pagy').show();
    }
  })

  $('#select-all-chapter').click(function() {
    const checkboxes = $('.chapters-pagy');
    for (let checkbox of checkboxes) {
      checkbox.checked = this.checked;
    }
  });

  $('#select-all').click(function() {
    const checkboxes = $('.chapters-not-pagy');
    for (let checkbox of checkboxes) {
      checkbox.checked = this.checked;
    }
  });
});
