$(document).ready(function () {
  var chapter_current;
  if ($('#js_lb_add_one').length) {
    chapter_current = parseInt(get_chapter_ajax($('#js_lb_add_one').data('book-id'))[0].number);
    var title = get_chapter_ajax($('#js_lb_add_one').data('book-id'))[0].title.replace(/\d+/g, chapter_current + 1);
    if ($('.field #crawl_chapter_url').length == 0 ) {
      $('#chapter_number').val(chapter_current + 1);
      $('#c_title_chap .input').val(title);
    }
  }
  if ($('#crawl_chapter_url').is(':visible') || $('#btn_add_audio').is(':visible')) {
    $('#c_add_audio_url').css('display', 'none');
  } else {
    $('#c_add_audio_url').css('display', 'flex');
  }
  $('.radio.radio_chapter').click(function () {
    var value = $('input[name="chapter[autolyse]"]:checked').val();
    if (value == 'many') {
      $('.error').text('');
      $('#js_add_multi').closest('form').attr('action', '/admin/books/' + this.dataset.id + '/chapters/create_multiple_chapter');
      $('#chapter_custome_action').css('display', 'block');
      $('#default_action').css('display', 'none');
      $('#c_title_chap .label').text('Chương cuối hiện tại');
      $('#c_title_chap .input').val('Chương ' + chapter_current);
      $('#c_title_chap .input').prop('disabled', true);
      $('#c_number_chap .label').text('Nhập số lượng chương muốn thêm');
      $('#chapter_number').val('');
      $('#c_add_audio_url').css('display', 'none');
      $('.card .card-content').css('display', 'none');

    } else {
      $('.error').text('');
      $('#c_title_chap .label').text('Tiêu đề');
      $('#c_number_chap .label').text('Số');
      $('#js_add_multi').closest('form').attr('action', this.dataset.url);
      $('#default_action').css('display', 'block');
      $('#chapter_custome_action').css('display', 'none');
      $('#c_title_chap .input').prop('disabled', false);
      $('#c_title_chap .input').val(title);
      $('#c_number_chap .input').val(chapter_current + 1);
      $('#c_add_audio_url').css('display', 'flex');
    }
  });

  $('#chapter_custome_action').click(function (e) {
    var number = $('#chapter_number').val();
    if (number == '') {
      validate_form(function () {
      });
    } else {
      var check = confirm('Bạn muốn thêm từ chương ' + (chapter_current + 1) + ' đến chương ' + (parseInt(number) + chapter_current));
      if (!check) {
        e.preventDefault();
      }
    }

  });

  $('#default_action').click(function (e) {
    var check = false;
    var book_id = $('#default_action .button').data('id');
    var arr_chapter = get_chapter_ajax(book_id);
    validate_form(function () {
      var title_num = $('#chapter_title').val().match(/\d+/)[0];
      var number = $('#chapter_number').val();
      if (title_num != number && title_num != undefined && number != '') {
        alert('Số chương và số thứ tự của chương không trùng khớp')
        e.preventDefault();
      } else {
        if (parseInt(number) <= chapter_current) {
          $.each(arr_chapter, function (index, value) {
            if (index == "number" && value == parseInt(number)) {
              check = true;
              return;
            }
          });
          if (check) {
            alert('Chương ' + number + ' đã tồn tại');
            e.preventDefault();
          }
        }
      }
    })
  })
});

function validate_form(_callBack) {
  $('#form_chapter').validate({
    rules: {
      'chapter[title]': {
        required: true,
      },
      'chapter[number]': {
        required: true,
        digits: true
      },
      'chapter[url]': {
        required: false
      }
    },
    messages: {
      'chapter[title]': 'Vui lòng nhập tên chương',
      'chapter[number]': {
        required: 'Vui lòng nhập số chương',
        digits: 'Vui lòng nhập số dương'
      },
      'chapter[url]': 'Vui lòng thêm audio'
    },
    submitHandler: function (form) {
      form.submit();
    }
  })
  _callBack();
}

function get_chapter_ajax(book_id) {
  var arr = [];
  $.ajax({
    'async': false,
    type: "GET",
    url: '/admin/books/' + `${book_id}` + '/chapters/get_all_chapters',
    success: function (data) {
      $.each(JSON.parse(data), function () {
        arr.push(this)
      });
    },
    error: function (data, status, xhr) {
      alert(error);
    }
  })
  return arr;
}
