import Swal from 'sweetalert2'
export default class TrixImplement {
  toggle = async (event) => {
    const target = event.currentTarget
    const inputEle = $(target).next('input')
    const trixEditor = document.querySelector('trix-editor')

    const content = inputEle.val()

    const { value: text } = await Swal.fire({
      input: 'textarea',
      inputLabel: 'Html Code',
      inputPlaceholder: 'Type your message here...',
      inputAttributes: {
        'class': ''
      },
      inputValue: content,
      showCancelButton: true
    })

    if (text) {
      trixEditor.editor.setSelectedRange([0, 1000000000])
      trixEditor.editor.insertHTML(text)
    }

  }
}
